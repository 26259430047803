import { useEffect, useMemo, useRef, useState } from "react";
import { uniq } from "lodash-es";
import classNames from "classnames";
import { BringData, Flag } from "@remhealth/icons";
import { DeprecatedReleaseFlags, MobileReleaseFlags, ReleaseFlags, mobileReleaseFlags, releaseFlags, versionedReleaseFlags, versionedReleaseMeta } from "@remhealth/host";
import { ProductChoice } from "@remhealth/core";
import { Classes, Dialog, FormField, IconButton, ListGrouping, ListOption, Tooltip } from "@remhealth/ui";
import { Practice } from "@remhealth/apollo";
import { PracticesPanel } from "./practicePanel";
import { FlagCount, FlagsSelect } from "./releaseFlagsSelect.styles";

type AllReleaseFlags = ReleaseFlags | MobileReleaseFlags | DeprecatedReleaseFlags;

export const allReleaseFlags = [...releaseFlags, ...versionedReleaseFlags, ...mobileReleaseFlags];

export interface ReleaseFlagsSelectProps {
  practiceId?: string;
  product: ProductChoice;
  allowCopy?: boolean;
  label: string;
  field: FormField<AllReleaseFlags[]>;
}

export const ReleaseFlagsSelect = (props: ReleaseFlagsSelectProps) => {
  const { product, field, label, allowCopy, practiceId } = props;

  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [flagsCopied, setFlagsCopied] = useState(false);

  const copyTimeout = useRef<ReturnType<typeof setTimeout>>();

  const copyButton = allowCopy ? (
    <Tooltip content={flagsCopied ? "Release flags copied!" : "Copy flags from another practice"} isOpen={flagsCopied ? true : undefined} placement="top">
      <IconButton square aria-label="Copy release flags" icon={<BringData />} onClick={openCopyDialog} />
    </Tooltip>
  ) : undefined;

  useEffect(() => {
    return clearCopyTimeout;
  }, []);

  const selectedItems = getSelectedReleaseFlags(field.value);
  const validFlags = useMemo(getValidReleaseFlags, [product]);

  return (
    <>
      <FlagsSelect
        clearable
        disabled={showCopyDialog}
        groupRenderer={groupReleaseFlags}
        items={validFlags}
        leftIcon={<Flag />}
        optionRenderer={renderReleaseFlag}
        placeholder={label}
        popoverProps={{
          maxWidth: 400,
        }}
        rightElement={copyButton}
        selectedItems={selectedItems}
        tagInputProps={{
          tagProps: (_: React.ReactNode, index: number) => {
            const flag = selectedItems[index];
            const added = !field.initialValue.includes(flag);
            return {
              minimal: !added,
              intent: added ? "primary" : "none",
            };
          },
        }}
        onSelectedItemsChange={handleReleaseFlagsChanged}
      />
      <Dialog
        isOpen={showCopyDialog}
        title="Choose a practice to copy release flags"
        onClose={closeCopyDialog}
      >
        <PracticesPanel
          singleSelect
          disabledReason={p => p.id === practiceId ? "This is the current practice" : null}
          practiceRenderer={practiceRenderer}
          selectedPractices={[]}
          onSelectionChanged={handleCopySelect}
        />
      </Dialog>
    </>
  );

  function renderReleaseFlag(flag: string) {
    const description = versionedReleaseMeta.find(m => m[1] === flag)?.[2];

    return (
      <ListOption multiline text={flag}>
        {flag}
        {description && <div className={classNames(Classes.TEXT_SMALL, Classes.TEXT_MUTED)}>{description}</div>}
      </ListOption>
    );
  }

  function practiceRenderer(practice: Practice) {
    const selectableFlags = getSelectedReleaseFlags(practice.releaseFlags);
    return (
      <>{practice.name} <FlagCount>({selectableFlags.length} flags)</FlagCount></>
    );
  }

  function clearCopyTimeout() {
    if (copyTimeout.current) {
      clearTimeout(copyTimeout.current);
    }
  }

  function getValidReleaseFlags() {
    const validVersionedFlags = versionedReleaseMeta.filter(v => v[0] === product).map(v => v[1]);
    return [...releaseFlags, ...validVersionedFlags, ...mobileReleaseFlags];
  }

  function getSelectedReleaseFlags(flags: string[]) {
    return allReleaseFlags.filter(f => flags.includes(f));
  }

  function handleReleaseFlagsChanged(flags: AllReleaseFlags[]) {
    field.onChange(uniq(flags));
  }

  function openCopyDialog() {
    setFlagsCopied(false);
    setShowCopyDialog(true);
  }

  function closeCopyDialog() {
    setShowCopyDialog(false);
  }

  function handleCopySelect(practices: Practice[]) {
    clearCopyTimeout();

    if (practices.length === 0) {
      return;
    }

    const practice = practices[0];
    const validFlags = getSelectedReleaseFlags(practice.releaseFlags);
    handleReleaseFlagsChanged(validFlags);

    closeCopyDialog();

    setFlagsCopied(true);
    copyTimeout.current = setTimeout(() => setFlagsCopied(false), 2000);
  }

  function groupReleaseFlags(flags: AllReleaseFlags[]): ListGrouping<AllReleaseFlags>[] {
    const remhealth = releaseFlags.filter(f => flags.includes(f));
    const versioned = versionedReleaseFlags.filter(f => flags.includes(f));
    const mobile = mobileReleaseFlags.filter(f => flags.includes(f));

    const groups: ListGrouping<AllReleaseFlags>[] = [
      { key: "Bells Web", items: remhealth },
    ];

    if (versioned.length > 0) {
      groups.push({ key: `${product === "Unknown" ? "EHR" : product} Version Dependent`, items: versioned });
    }

    if (mobile.length > 0) {
      groups.push({ key: "Bells Mobile", items: mobile });
    }

    return groups;
  }
};
