import { DateTime } from "luxon";
import type { CellObject, XLSX$Utils, WorkSheet as XlsxWorksheet, writeFileXLSX } from "sheetjs";
import { usePersonalPreferences } from "@remhealth/host";

export type WorksheetData = Record<string, string | number | Date | boolean | null | undefined>;
export type WorksheetCriteria = [label: string, value: string];

export interface WorkbookSaveOptions {
  /**
   * Appends date time to the filename
   * @default true
   */
  appendDateTime?: boolean;
}

export type WorksheetFormats<T extends WorksheetData> = {
  [K in keyof T]?: WorksheetFormat<T, K>;
}

export type WorksheetFormat<T extends WorksheetData, Col extends keyof T> =
  T[Col] extends number ? WorksheetNumberFormat : T[Col] extends Date ? WorksheetDateFormat : never;

export interface WorksheetNumberFormat {
  format?: "currency" | "percent" | "fixed" | "default";
  decimals?: number;
}

export interface WorksheetDateFormat {
  /** @default false */
  dateOnly?: boolean;
}

export interface Workbook {
  addSheet<T extends WorksheetData>(name: string): WorksheetGrid<T>;
  addSheet<T extends WorksheetData>(name: string, rows: T[], formats?: WorksheetFormats<T>): WorksheetGrid<T>;
  save(filename: string, options?: WorkbookSaveOptions): Promise<void>;
}

export interface Worksheet {
  readonly name: string;
  addHeaderLine(header?: string): void;
  addHeaderCriteria(label: string, value: string): void;
  addHeaderCriteria(criteria: WorksheetCriteria[]): void;
}

export interface WorksheetGrid<T extends WorksheetData> extends Worksheet {
  addRows(rows: T[]): void;
  setFormat<K extends keyof T>(column: K, format: WorksheetFormat<T, K>): void;
  setFormats(formats: WorksheetFormats<T>): void;
}

class SheetJsWorkbook implements Workbook {
  private readonly utils: XLSX$Utils;
  private readonly write: typeof writeFileXLSX;
  private readonly militaryTime: boolean;
  private sheets: SheetJsWorksheet<unknown & {}>[];

  constructor(utils: XLSX$Utils, write: typeof writeFileXLSX, militaryTime: boolean) {
    this.utils = utils;
    this.write = write;
    this.sheets = [];
    this.militaryTime = militaryTime;
  }

  public addSheet<T extends WorksheetData>(name: string): WorksheetGrid<T>;
  public addSheet<T extends WorksheetData>(name: string, rows: T[], formats?: WorksheetFormats<T>): WorksheetGrid<T>;
  public addSheet<T extends WorksheetData>(name: string, rows?: T[], formats?: WorksheetFormats<T>): WorksheetGrid<T> {
    const sheet = new SheetJsWorksheet<T>(this.utils, this.militaryTime, name, rows ?? [], formats ?? {});
    this.sheets.push(sheet);
    return sheet;
  }

  public save(filename: string): Promise<void> {
    const sheets: Record<string, XlsxWorksheet> = {};

    for (const sheet of this.sheets) {
      sheets[sheet.name] = sheet.create();
    }

    this.write({
      SheetNames: this.sheets.map(s => s.name),
      Sheets: sheets,
    }, `${filename}-${DateTime.now().toFormat("yyyy'-'MM'-'dd")}.xlsx`);

    return Promise.resolve();
  }
}

class SheetJsWorksheet<T extends WorksheetData> implements WorksheetGrid<T> {
  public readonly name;
  private readonly utils: XLSX$Utils;
  private readonly militaryTime: boolean;
  private rows: T[];
  private headerLines: (string | WorksheetCriteria)[];
  private formats: WorksheetFormats<T>;

  constructor(utils: XLSX$Utils, militaryTime: boolean, name: string, rows: T[], formats: WorksheetFormats<T>) {
    this.utils = utils;
    this.rows = rows;
    this.name = name;
    this.headerLines = [];
    this.militaryTime = militaryTime;
    this.formats = formats;
  }

  public addHeaderLine(header?: string): void {
    this.headerLines = [...this.headerLines, header ?? ""];
  }

  public addHeaderCriteria(label: string, value: string): void;
  public addHeaderCriteria(criteria: WorksheetCriteria[]): void;
  public addHeaderCriteria(arg1: string | WorksheetCriteria[], value?: string): void {
    if (typeof arg1 === "string") {
      this.headerLines = [...this.headerLines, [arg1, value!]];
    } else {
      this.headerLines = [...this.headerLines, ...arg1];
    }
  }

  public addRows(rows: T[]): void {
    this.rows = [...this.rows, ...rows];
  }

  public setFormat<K extends keyof T>(column: K, format: WorksheetFormat<T, K>): void {
    this.formats[column] = format;
  }

  public setFormats(formats: WorksheetFormats<T>): void {
    this.formats = formats;
  }

  public create(): XlsxWorksheet {
    const colCount = this.rows.length > 0 ? Object.keys(this.rows[0]).length - 1 : 0;
    const headerRow = this.headerLines.length > 0 ? this.headerLines.length + 1 : 0;

    let sheet = this.headerLines.length > 0 ? this.utils.aoa_to_sheet(this.headerLines.map(h => typeof h === "string" ? [h, ""] : h)) : this.utils.sheet_new();
    sheet = this.utils.sheet_add_json(sheet, this.rows, { cellDates: true, origin: { r: headerRow, c: 0 } });

    sheet["!merges"] = [];

    if (this.headerLines.length > 0) {
      for (let i = 0; i < this.headerLines.length; i++) {
        const mergeColStart = typeof this.headerLines[i] === "string" ? 0 : 1;
        sheet["!merges"].push({ s: { c: mergeColStart, r: i }, e: { c: colCount, r: i } });
      }

      sheet["!merges"].push({ s: { c: 0, r: this.headerLines.length }, e: { c: colCount, r: this.headerLines.length } });
    }

    sheet["!autofilter"] = { ref: this.utils.encode_range({ r: headerRow, c: 0 }, { r: headerRow, c: colCount }) };

    sheet["!cols"] = [];

    const colFormats: { [index: number]: WorksheetDateFormat | WorksheetNumberFormat | undefined } = {};

    for (let i = 0; i <= colCount; i++) {
      const colWidth = this.getColWidth(sheet, headerRow, i);
      sheet["!cols"][i] = {
        wpx: this.headerLines.length > 0 ? Math.min(150, colWidth) : colWidth,
      };

      const headerAddress = this.utils.encode_cell({ r: headerRow, c: i });
      const header = sheet[headerAddress] as CellObject | undefined;
      if (header) {
        const colFormat = this.formats[header.v as keyof T];
        if (colFormat) {
          colFormats[i] = colFormat;
        }
      }
    }

    // Set format of cells
    Object.keys(sheet).forEach((cellAddress) => {
      // Skip metadata like `!ref`
      if (cellAddress[0] === "!") {
        return;
      }

      const { c: col } = this.utils.decode_cell(cellAddress);
      const colFormat = colFormats[col];

      const cell = sheet[cellAddress] as CellObject;
      if (cell.v instanceof Date) {
        const showTime = colFormat ? (colFormat as WorksheetDateFormat).dateOnly !== false : true;
        cell.z = showTime ? this.militaryTime ? "mm/dd/yyyy H:mm" : "mm/dd/yyyy hh:mm AM/PM" : "mm/dd/yyyy";
      } else if (typeof cell.v === "number") {
        if (colFormat) {
          const numberFormat = colFormat as WorksheetNumberFormat;
          const formatType = numberFormat.format ?? "default";

          switch (formatType) {
            // 59.0%
            case "percent": {
              const decimals = numberFormat.decimals ?? 0;
              const decimalFormat = decimals > 0 ? ".0" + "#".repeat(decimals - 1) : "";
              cell.z = `0${decimalFormat}%`;
              break;
            }

            // $59.30
            // ($23.00)
            case "currency": {
              const decimals = numberFormat.decimals ?? 2;
              const decimalFormat = decimals > 0 ? "." + "0".repeat(decimals) : "";
              cell.z = `"$"#,##0${decimalFormat}_);\\("$"#,##0${decimalFormat}\\)`;
              break;
            }

            // 59.000
            case "fixed": {
              const decimals = numberFormat.decimals ?? 0;
              const decimalFormat = decimals > 0 ? "." + "0".repeat(decimals) : "";
              cell.z = `$#,##0${decimalFormat}`;
              break;
            }

            // 59.005
            // 59.4
            // 59
            default:
            case "default": {
              const decimals = numberFormat.decimals ?? 0;
              const decimalFormat = decimals > 0 && cell.v % 1 !== 0 ? ".0" + "#".repeat(decimals - 1) : "";
              cell.z = `$#,##0${decimalFormat}`;
              break;
            }
          }
        } else if (cell.v % 1 === 0) {
          cell.z = "#,##0";
        } else {
          cell.z = "#,##0.#######";
        }
      }
    });

    return sheet;
  }

  private getColWidth(sheet: XlsxWorksheet, headerRow: number, col: number): number {
    const maxWidth = 200;

    let row = headerRow;
    let cell = sheet[this.utils.encode_cell({ c: col, r: row })];

    // Min width is based on header
    const minWidth = cell ? Math.max(50, getValueWidth(cell.v)) : 50;

    cell = sheet[this.utils.encode_cell({ c: col, r: ++row })];

    // Sum widths to get an average
    let totalWidth = 0;
    while (cell) {
      totalWidth += getValueWidth(cell.v);
      cell = sheet[this.utils.encode_cell({ c: col, r: ++row })];
    }

    const avgWidth = totalWidth / row;
    return Math.min(maxWidth, Math.max(minWidth, avgWidth));
  }
}

export function useWorkbook() {
  const preferences = usePersonalPreferences();

  return { createWorkbook };

  async function createWorkbook(): Promise<Workbook> {
    const { utils, writeFileXLSX: writeFile } = await import("sheetjs");
    return new SheetJsWorkbook(utils, writeFile, preferences?.militaryTime ?? false);
  }
}

const avgCharLength = 7;
function getValueWidth(value: string | number | Date | boolean | Error | null | undefined): number {
  if (typeof value === "string") {
    return value.length * avgCharLength;
  } else if (value instanceof Date) {
    return 180;
  } else if (typeof value === "boolean") {
    return 50;
  } else if (typeof value === "number") {
    return Math.round(value).toString().length * avgCharLength;
  }

  return 50;
}
