import React, { useState } from "react";
import { uniq } from "lodash-es";
import { Blank, ChevronLeft, ChevronRight, Tick } from "@remhealth/icons";
import { Button, Classes, DialogStep, Icon, Menu, MenuDivider, MenuItem, ProgressBar, Radio, RadioGroup } from "@remhealth/ui";
import { Practice } from "@remhealth/apollo";
import { MobileReleaseFlags, ReleaseFlags, deprecatedReleaseFlags, mobileReleaseFlags, releaseFlags, versionedReleaseFlags } from "@remhealth/host";
import { forEachAsync, useErrorHandler } from "@remhealth/core";
import { useRegistry } from "~/contexts";
import { PracticesPanel } from "./practicePanel";
import { SizedDialog, SizedMultistepDialog } from "./releaseDialog.styles";

export interface ReleaseDialogProps {
  isOpen?: boolean;
  onClose: () => void;
}

interface ReleaseProgress {
  progress: number;
  total: number;
  hadError: boolean;
}

type AllReleaseFlags = ReleaseFlags | MobileReleaseFlags;
const allReleaseFlags = [...releaseFlags, ...mobileReleaseFlags];

export const ReleaseDialog = (props: ReleaseDialogProps) => {
  const { isOpen = false, onClose } = props;

  const registry = useRegistry();
  const handleError = useErrorHandler();

  const [step, setStep] = useState("action");
  const [selectedPractices, setSelectedPractices] = useState<Practice[]>([]);
  const [selectedFlags, setSelectedFlags] = useState<AllReleaseFlags[]>([]);
  const [revoke, setRevoke] = useState(false);
  const [progress, setProgress] = useState<ReleaseProgress>({ progress: 0, total: 0, hadError: false });

  const availableFlags = allReleaseFlags.filter(flag => {
    return selectedPractices.some(p => !p.allReleaseFlags && revoke ? p.releaseFlags.includes(flag) : !p.releaseFlags.includes(flag));
  });

  const canNext = step === "practices"
    ? selectedPractices.length > 0
    : true;

  const canFinal = step === "flags"
    ? selectedFlags.length > 0
    : false;

  const progressComplete = progress.progress === progress.total;

  return (
    <>
      <SizedMultistepDialog
        isCloseButtonShown
        backButtonProps={{ icon: <ChevronLeft /> }}
        finalButtonProps={{ disabled: !canFinal, intent: "primary", text: revoke ? "Revoke" : "Release", onClick: handleFinish }}
        isOpen={isOpen && progress.total === 0}
        nextButtonProps={{ disabled: !canNext, rightIcon: <ChevronRight /> }}
        title="Release"
        onChange={handleStepChange}
        onClose={onClose}
      >
        <DialogStep
          id="action"
          panel={<ActionsPanel revoke={revoke} onChoice={handleActionChange} />}
          title="Add or Revoke"
        />
        <DialogStep
          id="practices"
          panel={<PracticesPanel disabledReason={practiceDisabledReason} selectedPractices={selectedPractices} onSelectionChanged={setSelectedPractices} />}
          title="Choose Practices"
        />
        <DialogStep
          id="flags"
          panel={<FlagsPanel availableFlags={availableFlags} revoking={revoke} selectedFlags={selectedFlags} onSelectionChanged={setSelectedFlags} />}
          title="Choose Flags"
        />
      </SizedMultistepDialog>
      <SizedDialog aria-label="Practice Update Progress" isOpen={progress.total > 0}>
        <div className={Classes.DIALOG_BODY}>
          {progressComplete
            ? progress.hadError
              ? <h4>Some Tasks Not Created</h4>
              : <h4>Practices Updated</h4>
            : <h4>Updating Practices...</h4>}
          <ProgressBar intent={progress.hadError ? "danger" : progressComplete ? "success" : "primary"} stripes={!progressComplete} value={progress.progress / progress.total} />
          {progressComplete && <Button icon="tick" intent={progress.hadError ? "danger" : "success"} label="Close" onClick={handleClose} />}
        </div>
      </SizedDialog>
    </>
  );

  function practiceDisabledReason(practice: Practice) {
    return practice.allReleaseFlags ? <>Has all release flags enabled</> : null;
  }

  function handleActionChange(revoke: boolean) {
    setRevoke(revoke);
  }

  function handleStepChange(newDialogStepId: string) {
    setStep(newDialogStepId);
    setSelectedFlags([]);

    if (newDialogStepId === "practices") {
      setSelectedPractices([]);
    }
  }

  function handleClose() {
    onClose();
    setProgress({ progress: 0, total: 0, hadError: false });
  }

  async function handleFinish() {
    if (selectedPractices.length === 0 || selectedFlags.length === 0) {
      handleClose();
      return;
    }

    setProgress({ progress: 0, total: selectedPractices.length, hadError: false });

    await forEachAsync(5, selectedPractices, async practice => {
      selectedFlags.forEach(flag => {
        const index = practice.releaseFlags.indexOf(flag);
        if (revoke) {
          if (index !== -1) {
            practice.releaseFlags.splice(index, 1);
          }
        } else if (index === -1) {
          practice.releaseFlags.push(flag);
        }
      });

      const newFlags = [...allReleaseFlags.filter(flag => practice.releaseFlags.includes(flag)), ...versionedReleaseFlags.filter(flag => practice.releaseFlags.includes(flag)), ...deprecatedReleaseFlags];

      practice.releaseFlags = uniq(newFlags);

      try {
        await registry.practices.update(practice);
      } catch (error) {
        setProgress(prev => ({ ...prev, hadError: true }));
        handleError(error);
      } finally {
        setProgress(prev => ({ ...prev, progress: prev.progress + 1 }));
      }
    });
  }
};

interface ActionsPanelProps {
  revoke: boolean;
  onChoice: (revoke: boolean) => void;
}

function ActionsPanel(props: ActionsPanelProps) {
  const { revoke, onChoice } = props;

  return (
    <div className={Classes.DIALOG_BODY}>
      <RadioGroup selectedValue={revoke ? "revoke" : "add"} onChange={handleChoice}>
        <Radio large label="I want to add release flags" value="add" />
        <Radio large label="I want to revoke release flags" value="revoke" />
      </RadioGroup>
    </div>
  );

  function handleChoice(event: React.FormEvent<HTMLInputElement>) {
    onChoice(event.currentTarget.value === "revoke");
  }
}

interface FlagsPanelProps {
  revoking: boolean;
  availableFlags: AllReleaseFlags[];
  selectedFlags: AllReleaseFlags[];
  onSelectionChanged: (selectedFlags: AllReleaseFlags[]) => void;
}

function FlagsPanel(props: FlagsPanelProps) {
  const { revoking, availableFlags, selectedFlags, onSelectionChanged } = props;

  if (availableFlags.length === 0) {
    return (
      <div className={Classes.DIALOG_BODY}>
        {revoking
          ? "There are no flags to be revoked from the selected practices"
          : "The selected practices have all flags released."}
      </div>
    );
  }

  const remhealth = releaseFlags.filter(f => availableFlags.includes(f));
  const mobile = mobileReleaseFlags.filter(f => availableFlags.includes(f));

  return (
    <div className={Classes.DIALOG_BODY}>
      <Menu>
        <MenuDivider title="Bells Web" />
        {remhealth.map(renderFlag)}
        {mobile.length > 0 && (
          <>
            <MenuDivider title="Bells Mobile" />
            {mobile.map(renderFlag)}
          </>
        )}
      </Menu>
    </div>
  );

  function renderFlag(flag: AllReleaseFlags) {
    return (
      <MenuItem
        key={flag}
        info={<Icon icon={selectedFlags.includes(flag) ? <Tick /> : <Blank />} />}
        text={flag}
        onClick={() => toggleFlag(flag)}
      />
    );
  }

  function toggleFlag(flag: AllReleaseFlags) {
    if (selectedFlags.includes(flag)) {
      onSelectionChanged(selectedFlags.filter(f => f !== flag));
    } else {
      onSelectionChanged([...selectedFlags, flag]);
    }
  }
}
